@use "theme";

$primary-color: theme.$primary-color;
$accent-color: theme.$accent-color;
$warn-color: theme.$warn-color;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.step-indent-container {
  padding-left: 1.8rem;
}

.step-collapsible {
  overflow: hidden;
}

.step-indent {
  border-left: 0.1rem solid #dddddd;
  padding-left: 1.5rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.step-hint-text-style {
  font-size: smaller;
  margin: 0rem 0rem 1.5rem;
}

.step-column {
  max-width: 95%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.step-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.live-chat-bottom-sheet {
  height: 80vh !important;
  padding: 0 !important;

  .mat-bottom-sheet-container {
    padding: 0 !important;
  }
}
